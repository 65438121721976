import http from '@/utils/request'
/**
 * 公用接口
 */
export default {
    uploadImg: (data) => http({ method: 'post', url: '/wx/file/uploadImg', data }), // 已解答（分页列表）
    getRegion: (pid = 0) => http({ method: 'get', url: '/wx/system/region/getChildren?pid=' + pid }), // 获取地区列表
    getSearchWord: (position) => http({ method: 'get', url: `/dyh/web/searchWord/list?position=${position}` }), // 搜索热词_列表（1-药价查询， 2-免费用药）
    // 获取微信config配置
    getWxConfig: (data) => http({ method: 'get', url: `/wx/common/wechatParam?url=${data}` }),
}